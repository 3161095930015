/* metropolis regular */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: url('/resources/fonts/metropolis/Metropolis-Regular.woff2') format('woff2'),
  url('/resources/fonts/metropolis/Metropolis-Regular.woff') format('woff');
}
/* metropolis bold */
@font-face {
  font-family: 'MetropolisBold';
  font-style: normal;
  font-weight: 700;
  src: url('/resources/fonts/metropolis/Metropolis-Bold.woff2') format('woff2'),
  url('/resources/fonts/metropolis/Metropolis-Bold.woff') format('woff');
}

body {
  font-family: 'Metropolis';
}

div > h4 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

div > h4:first-child {
  margin-top: 0;
  margin-bottom: 0.2em;
}
strong {
  font-weight: 700;
}

/* headlines */
h1, h2, h3, h4, h5, h6 {
  font-family: 'MetropolisBold';
  margin: 0;
  padding: 0;
  color: $headline-color;
}
.h-hero {
  font-size: 3.375rem;
  line-height: 3.625rem;
  font-weight: bold;
  letter-spacing: -1px;
  span {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;
  }
  sup {
    font-size: 1.125rem!important;
  }
}
.h-primary {
  font-size: 2.125rem;
  line-height: 2.625rem;
  color: $purple;
  font-weight: bold;
}

/* paragraph */
p {
  font-family: 'Metropolis';
  font-weight: 400;
  margin: 20px 0 0 0;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: $dim-gray;
}
.p-lg {
  font-size: 1.5rem;
  line-height: 2.125rem;
}
.p-md {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

/* color */
.txt-white {
    color: $white;
}
