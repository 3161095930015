body.zertifikat {

  h3 {
    color: #006373;
  }

  input, select {
/*    font-family: 'Noto Sans', sans-serif;*/
    width: 100%;
    height: 58px;
    font-size: 16px;
    color: #64656A;
    letter-spacing: 0;
    line-height: 16px;
    background: #EBE8E5;
    border-radius: $border-radius;
    border: none;
    padding-left: 18px;
    margin-bottom: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;;

    &.missing {
      border: 1px solid red !important;
    }

    &[type="checkbox"] {
      display: none;

      + label::before {
        width: 29px;
        height: 29px;
        border-radius: $border-radius;
        background-color: #EBE8E5;
        display: block;
        content: "";
        float: left;
        margin-right: 5px;
      }

      + label.missing::before {
        border: $border-width solid red !important;
      }

      &:checked + label::before {
        content: '\2714';
        color: #ED7801;
      }
    }
  }
  label {
    margin-left: 10px;

    a {
      color: #ED7801;
      text-decoration: none;
    }
  }


  .space-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    span {
      font-size: 14px;
      color: #A8A7A8;
      letter-spacing: 0;
      text-align: center;
      line-height: 20px;
    }

    > div {
      font-size: 14px;
      color: #A8A7A8;
      letter-spacing: 0;
      text-align: center;
      @media (min-width: 1024px) {
        line-height: 29px;
      }
    }
  }
}

.select-wrapper {
  &::after {
    position: absolute;
    content: " ";
    background-image: url('/dist/img/arrow-down.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px;
    height: 18px;
    top: 25px;
    right: 36px;

    position: absolute;
    pointer-events: none;
  }
}

#login_form {
  text-align: center;
  padding-bottom: 70px;

  .form-group {
    display: inline-block;
    margin: 0 0.8em;

    input {
      line-height: 2.5em;
    }
  }

  button {
    margin: 0 0.8em;
  }

  @media (max-width: 760px) {
    .form-group {
      margin-bottom: 0.4em;
      width: 90%;
    }

    button {
      width: 90%;
    }
  }

}

.yform {
  .form-group {
    display: inline-block;
    margin: 0 0.8em;

    label {
      display: none;
    }

    input {
      background: $platinum;
      color: $dim-gray;
      border: none;
      border: $border-width solid transparent;
      border-radius: $border-radius;
      font-size: 16px;
      line-height: 2em;
      letter-spacing: 0;
      padding: .375rem .75rem;
      vertical-align: middle;
      width: 100%;
      min-width: 200px;

      &::placeholder {
        color: $dim-gray;
      }
    }

    /* Select styling CSS from https://filamentgroup.github.io/select-css/demo/ */
    select {
      display: block;
      font-size: 16px;
/*      font-family: sans-serif; */
/*      font-weight: normal; */
      color: $dim-gray;
      line-height: 1.3;
      padding: .65rem .75rem;
      width: 100%;
      min-width: 200px;
      max-width: 100%; /* useful when width is set to anything other than 100% */
      box-sizing: border-box;
      margin: 0;
      border: $border-width solid $platinum;
      border-radius: $border-radius;
      box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: $platinum;
      /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
          for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference

      */
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat, repeat;
      /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
      background-position: right .7em top 50%, 0 0;
      /* icon size, then gradient */
      background-size: .65em auto, 100%;

      /* Hide arrow icon in IE browsers */
      &::-ms-expand {
        display: none;
      }

      /* Hover style */
      &:hover {
        border-color: $platinum;
      }

      /* Focus style */
      &:focus {
        /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
        box-shadow: none;
        color: #222;
        outline: none;
      }

      /* Set options to normal weight */
      & option {
        font-weight: normal;
      }

      /* Disabled styles */
      &:disabled,
      &[aria-disabled=true] {
        color: graytext;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      }

      &:disabled:hover,
      &[aria-disabled=true] {
        border-color: $light-gray;
      }
    }
  }

  .field-info {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 15px;
    top: -13px;
    left: 17px;
    font-size: .8em;
    line-height: 1.4em;
    text-align: left;

    @media (max-width: 760px) {
      top: -9px;
    }
  }

  .formcheckbox {
    margin: 0.8em 0 0 0;
    margin-left: calc(0.8em + 10px);

    label {
      text-indent: -2.2em;
      margin-left: 2.2em;

      @media (max-width: 760px) {
        text-indent: -2.9em;
        margin-left: 2.5em;
      }
    }

    input[type=checkbox] {
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $dim-gray;
      background-color: $platinum;
      border: $border-width solid $platinum;
      border-radius: $border-radius;
      width: 25px !important;
      height: 25px;
      vertical-align: bottom;
      margin-right: 0.5em;

      @media (max-width: 760px) {
        top: 10px;
        font-size: 14px;
      }

      &:checked:after {
        position: absolute;
        content: "✔";
        top: -1px;
        left: 4px;
        color: $headline-color;
        font-size: 1.2em;
      }
    }
  }


  button {
    font-family: MetropolisBold;
    background: $btn-bg;
    color: #FFFFFF;
    border: $border-width solid transparent;
    border-radius: $border-radius !important;
    min-width: 175px;
    line-height: 2.5em;

    &:hover {
      background-color: $btn-bg-hover;
    }
  }

  .alert-danger {
    ul {
      list-style-type: none;
      color: red;
    }
  }

  .agenda-separator-bar {
    height: 80px;
  }
}

#yform-formular-arrival_transport label,
#yform-formular-arrival_start label,
#yform-formular-depart_transport label,
#yform-formular-depart_destination label {
  display: block;
  text-align: left;
  font-family: $fontBold;
}

#reg_form {
  text-align: center;
  padding-bottom: 70px;

  .form-group {
    display: inline-block;
    margin: 0.6em 0.8em;
    width: 90%;
  }
/*
  input {
    width: 320px;
  }
*/
  .formcheckbox {
    text-align: left;

    input {
      width: inherit;
    }
  }

  button {
    margin: 0 0.8em;
    margin-top: 12px;
    width: 250px;
  }

  .alert-danger {
  }

  .has-error {
    select,
    input {
      border: 1px solid red;
    }
  }
}

.dc-pwd-headline {
  font-size: 24px;
  line-height: 1.3em;
  color: yellow; /* $royal-blue; */
  padding: 1.3em 0;
}

#dc_login {
  overflow: hidden;
  height: 167px;
  width: 311px;
}

#pwd_login {
  position: relative;
  top: 0;
  width: 311px;
  height: 167px;
  background-color: white;
  margin-bottom: 2em;

  &:before {
    position: absolute;
    display: block;
    content: " ";
    background-image: url('../images/login-trenner.png');
    left: -46px;
    width: 43px;
    height: 167px;
  }

  h5 {
    color: white;
    background-color: #006373;
    font-weight: normal;
    font-size: 21px;
    line-height: 1.8em;
    text-align: left;
    padding: 0 0 0 .5em;
    margin: 0 0 1.3em 0;
  }

  input {
    display: block;
    margin: 0 auto;
    margin-top: .5em;
    width: 208px;
    padding: .33rem .66rem;
    font-size: 13px;

    &[type=password] {
      border-top: none;
      border-left: none;
      border-right: none;
      color: black;
    }

    &[type=submit] {
      font-weight: bold;
      background-color: #9c3;
      color: white;
      border: $border-width solid #9c3;
      border-radius: $border-radius;
      line-height: .8rem;
      -webkit-appearance: none;
    }
  }

  .err_msg {
    position: absolute;
    bottom: 0;
    color: #c03;
    font-size: 12px;
    line-height: 1.9em;
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 10px;
    background-color: white;
  }
}

@media (max-width: 414px) {
  #dc_login {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }

  #pwd_login {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    top: 0;

    &::before {
      display: none;
    }
  }

  #yform-formular-arrival_start label,
  #yform-formular-depart_destination label {
    display: none;
  }
}

/*
@media (min-width: 768px) {
  #yform-formular-email {
    margin-left: 0 !important;
  }
  #yform-formular-email2 {
    margin-right: 0 !important;
  }
}
*/
