footer {
    padding-top: .5em;
    background-color: $footer-bg;

  .align-right {
    text-align: right;

    @media (max-width: 992px) {
      text-align: center;
    }
  }
  p {
    font-size: 14px;
    color: white;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0.8em;
    margin-top: .4em;
  }

  a {
    color: white;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: 1.5rem;
    text-decoration: none;

    &:hover {
      color: $main-header-bg;
    }
  }
  @media (max-width: 1023px) {
    text-align: center;
  }
}
