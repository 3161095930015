#lefk-controls {
  .button {

    &.pre-lefk,
    &.post-lefk {
      display: none;
    }
  }

  &.activated {
    .button {
      pointer-events: all !important;
      opacity: 1 !important;
    }
  }
}

body.pre-lefk {
  #lefk-controls {
    .pre-lefk {
      display: inline-block;
      pointer-events: all;
      opacity: 1;
    }
  }
}

body.post-lefk {
  #lefk-controls {
    .post-lefk {
      display: inline-block;
      pointer-events: all;
      opacity: 1;
    }
  }
}

main[class^="page-topic"] {
  #lefk-controls {
    .button {
      pointer-events: none;
      opacity: .5;
      transition: opacity .3s ease-in-out;
    }
  }
}

.video-list {
  margin-bottom: 0;

  dt {
    font-weight: bold;
  }

  dd {}
}

.lefk-topic {
  position: relative;
  padding: 0.8em 1em;
  margin-bottom: 0.5em;
  background-color: #E2F0FA;

  .lefk-actions {
    display: inline-block;
    margin: 0 1em 0 0;
    position: absolute;
    right: 1em;
    bottom: 2em;

    .button {
      position: relative;
      padding-left: 20px;
      padding-right: 20px;
    }

    .rest-versuche {
      position: absolute;
      bottom: -40px;
      left: 0;
      width: 100%;
      color: #64656a;
      font-size: .8em;
    }
  }

  #get-certificate {
    display: none;
  }

  &.started {
    background-color: rgba(168, 167, 168, 0.25);

    &:before {
      content: '';
      position: absolute;
      top: 1.8em;
      right: 2em;
      width: 70px;
      height: 70px;
      background-image:url('/img/hourglass.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }

  &.finished {
    #get-certificate {
      display: inline-block;
    }
  }

  &.retries-0 {
    .lefk-actions {
      .button[data-topic^="t"] {
        display: none;
      }
    }
  }

  &.cme-2 {
    background-color: rgba(0, 144, 18, 0.25);

    &:before {
      content: '';
      position: absolute;
      top: .5em;
      right: 2em;
      width: 80px;
      height: 80px;
      background-image:url('/img/single-checked.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }

  &.cme-3 {
    background-color: rgba(0, 144, 18, 0.25);

    &:before {
      content: '';
      position: absolute;
      top: .5em;
      right: 2em;
      width: 80px;
      height: 80px;
      background-image: url('/img/double-checked.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
    }
  }
}

.video-grid {
  position: relative;

  .video-lnk {
    position: relative;
    padding: 0.8em 1em;
    margin-bottom: 0.5em;
    background-color: #E2F0FA;

    &.viewed {
      background-color: rgba(0, 144, 18, 0.25);

      .button:before {
        position: absolute;
        left: -80px;
        top: 25%;
        content: '';
        width: 58px;
        height: 27px;
        background-image:url('/img/eye.svg');
      }
    }

    .button {
      position: absolute;
      right: 1em;
      bottom: .8em;
    }
  }
}

#lefk_form {
  margin-top: 2em;

  #formular {
    padding-left: 15px;
    padding-right: 15px;
  }

  h3.question {
    display: block;
  }

  button[type=submit] {
    display: none;
  }
  fieldset {
    margin-bottom: 3em;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1em;
      border-bottom: 3px solid $platinum;
    }

    &:nth-child(3):after {
      border-bottom: none;
    }
  }

  .formcheckbox {
    margin-left: .8em;

    label {
      padding-left: 2.3em;
      text-indent: -2.3em;
    }

    input[type=checkbox]:checked:after {
      font-weight: bold;
      color: $btn-bg-hover;
    }
  }

  span[class^="mark-"] {
    text-decoration: underline;
  }


  /* DEBUG */
  /*
  input[data-true]:hover {
    outline: 1px solid green;
  }
  */
}
