#heroimage {
  height: 650px;
  min-height: 650px;
  background-image: url('/img/heroimage@2x.jpg');
  background-size: cover;
  background-position: right;
  display: block;

  h1, p {
    color: white;

    .product-name {
      font-size: 32px;
      font-family: $fontRegular;
    }
  }

  @media (max-width: 1023px) {
    height: 100vh;
    background-position: center;
  }

  .container {
    height: 100%;

    .row {
      height: 100%;

      div[class^=col-] {
        margin: auto 0;

        p {
          margin-top: 10px;
        }
      }

      .p-md {
        opacity: 0.7;
      }

      .subline {
        font-size: 1em;
        line-height: 1.4em;
      }

      a.btn {
        margin-top: 1.5em;
      }
    }
  }
}

#title-bar {
  position: fixed;
  background-color: $biomarin-blue;
  margin-top: 137px;
  height: 82px;
  width: 100%;
  z-index: 1;
  transition: all 150ms linear;

  .container {
    display: table;
    height: 88px;

    h1 {
      color: white;
      font-size: 38px;
      line-height: 1.2em;
      display: table-cell;
      vertical-align: middle;
      height: 88px;
    }
  }

  &.bg-blue {
    background-color: $biomarin-blue;
  }
  &.bg-orange {
    background-color: $biomarin-orange;
  }
  &.bg-red {
    background-color: $biomarin-red;
  }
  &.bg-magenta {
    background-color: $biomarin-magenta;
  }
  &.bg-violet {
    background-color: $biomarin-violet;
  }
  &.bg-purple {
    background-color: $biomarin-purple;
  }
  &.bg-blue-dark {
    background-color: $biomarin-blue-dark;
  }

}

.agenda-separator-bar {
  background-color: $platinum;
  margin-top: 2em;
  margin-bottom: 2em;
  height: 70px;

  .container {
    display: table;
    height: 70px;

    h3 {
      color: $headline-color;
      font-size: 20px;
      line-height: 1.2em;
      display: table-cell;
      vertical-align: middle;
      height: 70px;
      padding-left: 15px;

      .thin {
      }
    }

    button[type=submit] {
      color: $white;
      background: $biomarin-orange;
      border: none;
      text-align: center;
      cursor: pointer;
      transition: color .2s ease-in-out,  background-color .2s ease-in-out !important;

      &:hover {
        background: $biomarin-cyan;
        color: $white;
      }
    }
  }
}

.action-separator-bar {
  margin-top: 80px;
  margin-bottom: 80px;
  .button {
    text-align: center;
    width: 100%;
  }
}
