html, body {
  margin: 0;
  padding: 0;

  @media (max-width:1023px) {
    height: auto;
  }

  @media (max-width:700px) {
    font-size: 12px;
  }
}

html {
  height: 100%
}

body {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  color: $dim-gray;
}

a[href^='tel'] {
  color: $dim-gray;
}

.flex {
  display: flex;

  &.flex-center {
    justify-content: center;
  }
}

.hidden {
  display: none;
}

.bg-gray {
    background-color: $biomarin-gray-dark;
}

.txt-biomarin-orange {
  color: $biomarin-orange;
}

.button {
  display: inline-block;
  font-family: $fontRegular;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: $white;
  background-color: $biomarin-orange;
  text-align: center;
  text-decoration: none !important;
  padding: 18px 30px;
  cursor: pointer;
  transition: color .2s ease-in-out,  background-color .2s ease-in-out !important;

  &.bg-blue {
    background-color: $biomarin-blue;
  }
  &.bg-orange {
    background-color: $biomarin-orange;
  }
  &.bg-red {
    background-color: $biomarin-red;
  }
  &.bg-violet {
    background-color: $biomarin-violet;
  }
  &.bg-magenta {
    background-color: $biomarin-magenta;
  }
  &.bg-purple {
    background-color: $biomarin-purple;
  }
  &.bg-blue-dark {
    background-color: $biomarin-blue-dark;
  }

  &:hover {
    background-color: $biomarin-cyan;
    color: $white;
  }

/*
  &.button-warm-gradient {
    background: #D7D2CB;
    background-image: linear-gradient(to right, #DA0812 0%, #E44009, #ED7801 50%, #DA0812 100%);
    background-size: 200% auto;
    transition: all 500ms ease-in-out;
    background-position: right center;
    &:hover {
      background-position: left center;
    }
  }
  &.button-warm-gray {
    color: #A8A7A8;
    background: #D7D2CB;
    border-radius: 4px;
  }
  &.button-petrol {
    background: $petrol;
    color: #FFFFFF;

    &:hover {
      background-color: $royal-blue;
    }
  }
  &.button-egg-blue {
    background: #71B1C8;
    color: #006373;
  }
  &.button-royal-blue {
    background-color: $royal-blue;
    color: white;
  }
  &.disabled {
    cursor: not-allowed;
  }

  &.button-light-blue {
    background-color: $light-blue;
    color: $petrol;

    &:hover {
      background-color: $petrol;
      color: white;

    }
  }

  &.progress {
    pointer-events: none;
    background-image: url('/img/loading-spinner.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent !important;
    color: transparent !important;
    border: none !important;
  }
  */
}

#intro {
  padding-top: 120px;
  padding-bottom: 120px;

  h2 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
}

#start-webcast {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: yellow; /* $moonstone-blue; */

  .button-col {
    justify-content: center;
  }
}

#referenten {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #EBE8E5;

  img {
    max-width: 100%;
    height: auto;

    @media (max-width: 1023px) {
      margin: 0 auto;
      display: block;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #006373;
    letter-spacing: 0;
    line-height: 24px;

    @media (max-width: 1023px) {
      text-align: center;
    }
  }

  p {
    @media (max-width: 1023px) {
      text-align: center;
    }
  }

  .referent {
    margin-bottom: 80px;
  }
}


pre#debug {
  position: absolute;
  background-color: black;
  color: white;
  padding: 15px 30px 0px 30px;
  top: 15px;
  left: 15px;
  z-index: 9999;
  margin: 0;
  transition: all 300ms ease;
  font-size: 60%;

  &:hover {
    opacity: 0.1;
  }
}

.agenda-table-topic {
  font-family: $fontBold;
  font-size: 34px !important;
  line-height: 1em;
  color: $btn-bg-hover;
  letter-spacing: 0;
  margin-bottom: 1em;

  .thin {
    font-family: $fontRegular;
    font-size: 24px;
  }
}

.agenda-table {
  position: relative;
  margin-left: 190px;

  @media (max-width:700px) {
    margin-left: 0px;
    margin-bottom: 30px;
  }

  &:before {
    content: "" attr(data-time) "";
    position: absolute;
    left: -190px;
    top: 0;
    font-family: $fontBold;

    @media (max-width:700px) {
      left: 0px;
      top: -20px;
    }
  }
}

.page-agenda {
  .section-text {
    margin-bottom: 3em;
  }
}

.page-reg_danke {
  .section-text {
    text-align: center;

    h4 {
      margin-bottom: 1.5em;
    }
  }
}

.section-text {
  div[class^="col-"] {
/*    padding-left: 0; */
  }

  table {
    width: 100%;
  }

  .agenda-table:last-of-type {
    margin-bottom: 3em;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  blockquote {
    strong {
      color: $btn-bg;
    }
  }
}

.stream-placeholder {
  width: 100%;
  height: 100px;
  background-color: $platinum;
  text-align: center;
  line-height: 100px;
  margin-bottom: 50px;
  font-style: italic;
}


/* margin */
.mt40 {
  margin-top: 40px
}
.mt20 {
  margin-top: 20px
}
.mt10 {
  margin-top: 10px
}

#triangle-disclaimer {
  background-color: #9DC3DF;
  p {
    font-size: 14px;
    line-height: 20px;
    color: $text-dark-blue;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}
