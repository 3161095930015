#nav {

  .progress {
    justify-content: space-between;
    span {
      line-height: 79px;
      display: block;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0;
      position: relative;
      cursor: no-drop;

      @media (max-width: 1023px) {
        display: none;
      }

      &.active {
        color:  green; /* $tangerine; */
        cursor: default;

        @media (max-width: 1023px) {
          display: block;
        }

        &:after {
          position: absolute;
          top: 60px;
          height: 5px;
          left: 0;
          width: 100%;
          content: ' ';
          background-color: green; /* $tangerine; */
        }
      }
    }
  }
}

.certificate-header {
  background-color: #006373;

  p {
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 30px;
  }
}

#next-webcast {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #EBE8E5;

  .button-col {
    justify-content: center;
  }
}


#login {
  background-color: #EBE8E5;
  flex-grow: 1;
  display: flex;

  @media (max-width: 1023px) {
    padding: 60px 0;
    display: block;
  }

  .container {
    margin-top: auto;
    margin-bottom: auto;
  }

  #dc_login {
    @media (max-width: 1023px) {
      text-align: center;
    }
  }

  #pwd_login {
    width: 311px;
    height: 162px;
    background-color: white;

    @media (max-width: 1023px) {
      margin: 0 auto;
    }

    &:before {
      position: absolute;
      display: block;
      content: " ";
      background-image: url('../images/login-trenner.png');
      left: -46px;
      width: 43px;
      height: 167px;
    }

    h5 {
      color: white;
      background-color: #0069b4;
      font-weight: normal;
      font-size: 21px;
      line-height: 1.8em;
      text-align: left;
      padding: 0 0 0 .5em;
      margin: 0 0 1.3em 0;
    }

    input {
      display: block;
      margin: 0 auto;
      margin-top: .5em;
      width: 208px;
      padding: .33rem .66rem;
      font-size: 13px;

      &[type=password] {
        border-top: none;
        border-left: none;
        border-right: none;
        color: black;
      }

      &[type=submit] {
        font-weight: bold;
        background-color: #9c3;
        color: white;
        border: 1px solid #9c3;
        border-radius: 5px;
        line-height: .8rem;
        -webkit-appearance: none;
      }
    }

    .err_msg {
      color: #c03;
      font-size: 12px;
      line-height: 1.9em;
      width: 311px;
      background-color: #eee;
      margin-top: 38px;
      height: 25px;
    }
  }
}

main {
  flex-grow: 1;
  width: 100%;
/*  display: flex; */
  padding: 70px 0;
  margin-top: 220px;
  position: relative;

  &.page-startseite {
    margin-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: 1023px) {
    display: block;
  }

  @media (max-height: 1100px) {
    display: block;
  }

  section {
    margin: auto;
  }
}

#overview {
  text-align: center;

  p {
    padding: 0 30px;

    &.step {
      padding: 0;
      font-size: 20px;
      color: #006373;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: bold;

      span {
        display: block;
        font-weight: normal;
      }
    }
  }
}

.question {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  @media (max-width: 1023px) {
    height: auto;
  }

  .answers {
    .answer {
      padding: 20px;
      border: 2px solid #A8A7A8;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 20px;
      color: #64656A;
      letter-spacing: 0;
      line-height: 24px;
      display: flex;
      cursor: pointer;

      @media (max-width: 1023px) {
        margin: 5px auto;
        max-width: 95%;
      }

      @media (max-width: 1024px) {
        padding: 10px;
      }

      span {
        font-size: 50px;
        line-height: 50px;
        margin-right: 22px;
      }

      p {
        margin-top: auto;
        margin-bottom: auto;
      }

      &.selected {
        color: white;
        background: #ED7801;
        border: 2px solid #ED7801;
      }
    }
  }
}

.progress {
  display: flex;
  justify-content: center;

  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 145px;
    padding-left: 0;
    margin: 0;
    margin-bottom: 30px;

    li {

      content: ' ';
      background: #D7D2CB;
      width: 10px;
      height: 10px;
      border-radius: 10px;

      &.active {
        background: #ED7801;
      }
    }
  }
}

#result-success {
  pointer-events: all;

  p {
    text-align: center;
    font-size: 38px;
    color: #ED7801;
    letter-spacing: 0;
    text-align: center;
    line-height: 42px;
  }
}

#result-failure {
  pointer-events: all;

  p {
    font-size: 20px;
    color: #64656A;
    letter-spacing: 0;
    text-align: center;
    line-height: 30px;

    strong {
      font-size: 38px;
      color: #DA0812;
      letter-spacing: 0;
      text-align: center;
      line-height: 42px;
    }

    small {
      font-size: 14px;
      color: #A8A7A8;
      letter-spacing: 0;
      text-align: center;
      line-height: 20px;
    }
  }
}

.disabled {
  position: absolute;
  height: 0;
  visibility: hidden;
  pointer-events: none;
}

/* VIDEO JS */
.video-js {
  max-width: 100%;
  background-color: transparent;

  .vjs-progress-control {
    &.vjs-control {
      pointer-events: none;
    }
  }

  .vjs-time-controls,
  .vjs-time-divider,
  .vjs-captions-button,
  .vjs-subs-caps-button {
    display: none;
  }

  .vjs-control-bar {
    justify-content: space-between;
  }
  .vjs-ended {
    display: none;
  }
}
