#nav {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
  background-color: rgba(255,255,255, 0);
  z-index: 99;
  box-shadow: 0 3px 6px rgba(0,0,0,0), 0 3px 6px rgba(0,0,0,0);
  transition: all 150ms linear;

  a {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 300ms linear;

    &.icon {
      height: 100%;
      margin-left: initial;
      display: inline-flex;

      &.icon-white {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    @media (max-width: 1023px) {
      font-size: 0.7rem;
    }
  }

  img {
    margin-top: auto;
    margin-bottom: auto;
    transition: all 300ms linear;
    height: auto;
    width: auto;

    @media (max-width:700px) {
      max-width: 80%;
    }
  }

  &.thin {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: rgba(255,255,255, 1);
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.2);

    img.black-white {
      filter: invert(1);
      margin: 1em 0;
    }

    a.button {
      transform: scale(0.8);
      transform-origin: right;
      transition: all 300ms linear;
    }

    a.icon {
      height: 60%;

      &.icon-white {
        img {
          filter: none;
        }
      }

      img {
        height: 100%;
        width: auto;
        transition: all 300ms linear;
      }
    }

    & + #title-bar {
      margin-top: 54px;

      @media (max-width: 760px) {
        margin-top: 49px;
      }
    }
  }
}

body #nav {
  position: fixed;
}
